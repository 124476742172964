import { Card, Carousel } from 'antd';
import { TitleText } from 'components/CommonComponents/Titles/styled';
import { StyledSection } from 'components/CommonComponents/Wrappers/styled';
import React, { useState } from 'react';
import sanitizeHtml from 'sanitize-html';
import { Description, Wrapper } from './styled';

const Blog = () => {
  const [data] = useState<any[]>([]);

  // useEffect(() => {
  //   const getData = async () => {
  //     const medium = await fetch('https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/@Qest');
  //     const mediumData = await medium.json();
  //     setData(mediumData.items);
  //   };
  //   getData();
  // }, []);

  return data?.length ? (
    <StyledSection id="blog">
      <Wrapper>
        <>
          <TitleText>Blog</TitleText>
          <Carousel autoplay={true}>
            {data.map((item, index) => {
              const previewText = sanitizeHtml(item.description, {
                allowedTags: [],
                allowedAttributes: {},
                allowedIframeHostnames: [],
              });
              const dotIndex = previewText.slice(0, 300).lastIndexOf('.') + 1;
              return (
                <a href={item.link} key={index}>
                  <div key={index} style={{ maxHeight: 300 }}>
                    <Card title={item.title} cover={<img alt={item.title} src={item.thumbnail} />}>
                      <Description>{previewText.slice(0, dotIndex)}</Description>
                    </Card>
                  </div>
                </a>
              );
            })}
          </Carousel>
        </>
      </Wrapper>
    </StyledSection>
  ) : null;
};

export default Blog;
