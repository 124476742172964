import Blog from 'components/Blog';
import Footer from 'components/CommonComponents/Footer';
import NavigationBar from 'components/CommonComponents/NavigationBar';
import SEO from 'components/CommonComponents/SEO';
import { MainContainer } from 'components/CommonComponents/Wrappers/styled';
import Intro from 'components/PageHome/Intro';
import { AndroidImage, SteveImage } from 'components/PageHome/Intro/styled';
import KnowHow from 'components/PageHome/KnowHow';
import OurOffices from 'components/PageHome/OurOffices';
import Projects from 'components/PageHome/Projects';
import WeAre from 'components/PageHome/WeAre';
import { GlobalStyle } from 'constants/globalTheme';
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { ReactBasicScroll } from 'react-basic-scroll';

const pageQuery = graphql`
  {
    weareYaml {
      images {
        publicURL
      }
    }
    messageusYaml {
      name {
        cs
      }
      header {
        cs
      }
      email {
        cs
      }
      buttonText {
        cs
      }
      message {
        cs
      }
    }
  }
`;

const parallaxOne = {
  from: 'top-top',
  to: 'bottom-bottom',
  props: {
    '--opa1': {
      from: 0.1,
      to: 1,
    },
    '--ty1': {
      from: '-100px',
      to: '100px',
    },
  },
};

const parallaxTwo = {
  from: 'top-top',
  to: 'bottom-bottom',
  props: {
    '--opa2': {
      from: 0.1,
      to: 2,
    },
    '--ty2': {
      from: '300px',
      to: 0,
    },
  },
};

const IndexPage = () => {
  const data = useStaticQuery(pageQuery);
  const images = data.weareYaml.images.map((a: any) => a.publicURL);

  return (
    <>
      <SEO title="QEST" />
      <GlobalStyle />

      <NavigationBar />
      <MainContainer>
        <ReactBasicScroll config={parallaxOne}>
          <AndroidImage src={images[0]} alt={images[0].name} />
        </ReactBasicScroll>
        <ReactBasicScroll config={parallaxTwo}>
          <SteveImage src={images[1]} alt={images[1].name} />
        </ReactBasicScroll>
        <WeAre />
        <Intro />
        <Projects />
        <KnowHow />
        <Blog />
        <OurOffices />
      </MainContainer>
      <Footer />
    </>
  );
};

export default IndexPage;
