import { TitleText } from 'components/CommonComponents/Titles/styled';
import { StyledSection } from 'components/CommonComponents/Wrappers/styled';
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { AlignWrapper, KnowItemDescription, KnowItemsWrapper, KnowItemTitle, KnowItemWrapper, StyledDiv, StyledImage } from './styled';
import Tech from './subComponents/Tech';

const pageQuery = graphql`
  {
    allKnowhowlistYaml {
      nodes {
        heading {
          cs
        }
        description {
          cs
        }
      }
    }
    knowhowYaml {
      image {
        publicURL
      }
      mainHeading {
        cs
      }
      techMainHeading {
        cs
      }
    }
  }
`;

const KnowHow = () => {
  const locale = 'cs';
  const data = useStaticQuery(pageQuery);

  const knowHowList = data.allKnowhowlistYaml.nodes;

  return (
    <StyledSection>
      <StyledDiv imageLink={data.knowhowYaml.image.publicURL}>
        <StyledImage alt={'phone background'} src={data.knowhowYaml.image.publicURL} />
        <TitleText>{data.knowhowYaml.mainHeading[locale]}</TitleText>
        <AlignWrapper>
          <KnowItemsWrapper>
            {knowHowList.map(({ heading, description }: any, index: number) => (
              <KnowItemWrapper key={`know-${index}`}>
                <KnowItemTitle>{heading[locale]}</KnowItemTitle>
                <KnowItemDescription>{description[locale]}</KnowItemDescription>
              </KnowItemWrapper>
            ))}
          </KnowItemsWrapper>
          <Tech mainHeading={data.knowhowYaml.techMainHeading[locale]} />
        </AlignWrapper>
      </StyledDiv>
    </StyledSection>
  );
};

export default KnowHow;
