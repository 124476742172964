import styled, { DefaultTheme, ThemeProps } from 'styled-components';

interface ImageProps extends ThemeProps<DefaultTheme> {
  imageLink: string;
}

export const KnowItemsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 100px;
  align-items: center;
  @media screen and (min-width: ${({ theme }) => theme.layout.md}px) {
    flex-direction: row;
    flex-wrap: wrap;
  }
  @media screen and (min-width: ${({ theme }) => theme.layout.lg}px) {
    margin-bottom: 150px;
  }
`;

export const KnowItemWrapper = styled.div`
  margin-bottom: 50px;
  @media screen and (min-width: ${({ theme }) => theme.layout.md}px) {
    width: calc(50% - 50px);
    margin-bottom: 50px;
    margin-right: 25px;
    margin-left: 25px;
  }
  @media screen and (min-width: ${({ theme }) => theme.layout.lg}px) {
    margin-left: 0;
    margin-right: 65px;
    width: 33%;
  }
`;

export const KnowItemTitle = styled.h3`
  font-family: 'Roboto Mono';
  font-weight: 700;
  font-size: 24px;
  text-align: center;
  color: ${({ theme }) => theme.color.text.primary};
  @media screen and (min-width: ${({ theme }) => theme.layout.lg}px) {
    text-align: initial;
  }
`;

export const KnowItemDescription = styled.p`
  color: ${({ theme }) => theme.color.text.secondary};
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
  text-align: center;
  margin-top: 0px;
  margin-bottom: 0px;
  @media screen and (min-width: ${({ theme }) => theme.layout.lg}px) {
    text-align: initial;
  }
`;

export const PhoneImage = styled.img`
  position: absolute;
  z-index: -2;
  top: 50px;
  left: 0px;
  max-width: 100%;
  @media screen and (min-width: ${({ theme }) => theme.layout.md}px) {
    top: 0px;
  }
`;

export const AlignWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  @media screen and (min-width: ${({ theme }) => theme.layout.lg}px) {
    padding-top: 100px;
    align-items: flex-start;
  }
`;

export const StyledDiv = styled.div`
  background-image: none;
  background-repeat: no-repeat;
  background-position: 100% 100%;
  background-size: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 100px;
  @media screen and (min-width: ${({ theme }) => theme.layout.lg}px) {
    background-image: url(${({ imageLink }: ImageProps) => imageLink});
  }
`;

export const StyledImage = styled.img`
  width: 60%;
  height: auto;
  margin-bottom: 100px;
  @media screen and (min-width: ${({ theme }) => theme.layout.lg}px) {
    display: none;
  }
`;

export const ImageCar = styled.img`
  max-width: 100%;
  width: auto;
  height: auto;
  margin-bottom: 100px;
`;
