import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  top: -110px;
  height: 100vh;
`;

export const WeAreHeading = styled.p`
  font-size: 37px;
  font-weight: 700;
  letter-spacing: 4px;
  margin-bottom: 10px;
  color: ${({ theme }) => theme.color.text.primary};
`;

export const WeAreDevelopers = styled.p`
  min-height: 141px;
  color: ${({ theme }) => theme.color.text.accent};
  font-size: 100px;
  text-transform: uppercase;
  font-weight: 700;
  margin: 0 0 10px 0;
  font-size: 100px;
  @media screen and (max-width: ${({ theme }) => theme.layout.md}px) {
    font-size: 50px;
    margin: 0;
  }
  @media screen and (min-width: ${({ theme }) => theme.layout.lg}px) {
    font-size: 125px;
  }
`;

export const WeAreProviding = styled.p`
  line-height: 1.42857143;
  letter-spacing: 3px;
  font-weight: 600;
  font-size: 27px;
  color: ${({ theme }) => theme.color.text.primary};
  text-align: center;
  margin: 0;
`;
