import styled from 'styled-components';

export const Wrapper = styled.div`
  color: white;
  max-width: 800px;
  width: 60%;
  margin-bottom: 100px;

  .ant-carousel .slick-slide {
    text-align: center;
    height: 780px;
    line-height: 780px;
    max-width: 600px;
    overflow: hidden;
  }

  .ant-card-bordered {
    border: none;
  }

  .ant-card {
    background: transparent;
  }

  .ant-card-head-title {
    font-size: 20px;
    color: white;
    opacity: 0.6;
  }
`;

export const Description = styled.span`
  color: white;
  opacity: 0.6;
  font-size: 18px;
`;
