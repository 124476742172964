import { Link } from 'gatsby';
import styled, { DefaultTheme, ThemeProps } from 'styled-components';

interface ImageProps extends ThemeProps<DefaultTheme> {
  backgroundColor: string;
}

export const DescriptionWrapper = styled.div``;

export const Wrapper = styled.div`
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  width: 100%;
  min-width: 300px;
  flex-direction: row;
`;

export const HighlightProjectWrapper = styled.div`
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  width: 100%;
  @media screen and (min-width: ${({ theme }) => theme.layout.lg}px) {
    flex-direction: row;
  }
`;

export const ProjectWrapper = styled.div`
  margin-bottom: 30px;
  width: calc(50% - 5px);
  :nth-child(even) {
    margin-right: 10px;
  }
  @media screen and (min-width: ${({ theme }) => theme.layout.md}px) {
    width: calc(50% - 15px);
    :nth-child(even) {
      margin-right: 30px;
    }
  }
  @media screen and (min-width: ${({ theme }) => theme.layout.lg}px) {
    flex: 1;
    :nth-child(even) {
      margin-right: 0px;
    }
    :not(:last-child) {
      margin-right: 30px;
    }
  }
`;

export const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 150px;
  background: ${({ backgroundColor }: ImageProps) => backgroundColor};
  margin-bottom: 20px;
  width: 100%;
  img {
    max-height: 75%;
    max-width: 75%;
  }
  @media screen and (min-width: ${({ theme }) => theme.layout.md}px) {
    height: 300px;
  }
`;

export const HighlightImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 300px;
  background: ${({ backgroundColor }: ImageProps) => backgroundColor};
  @media screen and (min-width: ${({ theme }) => theme.layout.md}px) {
    height: 400px;
  }
  @media screen and (min-width: ${({ theme }) => theme.layout.lg}px) {
    width: 95%;
    margin-right: 30px;
    height: 450px;
  }
  img {
    max-width: 75%;
    height: auto;
  }
`;

export const StyledLink = styled(Link)`
  width: 100%;

  @media screen and (min-width: ${({ theme }) => theme.layout.lg}px) {
    width: 69%;
  }
`;

export const HighlightDescription = styled.div``;

export const ProjectName = styled.h3`
  font-family: 'Roboto Mono';
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  color: ${({ theme }) => theme.color.text.primary};
`;

export const HighlightProjectName = styled(ProjectName as any)`
  margin-top: 25px;
  margin-bottom: 25px;
  font-size: 35px;
  @media screen and (min-width: ${({ theme }) => theme.layout.md}px) {
    font-size: 44px;
  }
  @media screen and (min-width: ${({ theme }) => theme.layout.lg}px) {
    margin-top: 0px;
    font-size: 44px;
  }
`;

export const ProjectDescription = styled.p`
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
  color: ${({ theme }) => theme.color.text.secondary};
  margin-top: 0px;
  margin-bottom: 0px;
  @media screen and (min-width: ${({ theme }) => theme.layout.md}px) {
    max-width: 300px;
  }
`;

export const StyledImagePhone1 = styled.img`
  position: relative;
  align-self: flex-start;
  max-width: 127px;
  height: auto;
  left: 50px;
  top: -30px;
  margin-bottom: 60px;
  @media screen and (min-width: ${({ theme }) => theme.layout.lg}px) {
    left: 100px;
  }
`;

export const StyledImagePhone2 = styled.img`
  position: relative;
  align-self: flex-end;
  max-width: 108px;
  height: auto;
  right: 50px;
  top: -50px;
  @media screen and (min-width: ${({ theme }) => theme.layout.lg}px) {
    right: 100px;
  }
`;

export const Separator = styled.div`
  margin-bottom: 0px;
  @media screen and (min-width: ${({ theme }) => theme.layout.md}px) {
    margin-bottom: 50px;
  }
  @media screen and (min-width: ${({ theme }) => theme.layout.lg}px) {
    margin-bottom: 100px;
  }
`;
