import { TitleText } from 'components/CommonComponents/Titles/styled';
import { StyledSection } from 'components/CommonComponents/Wrappers/styled';
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { Text, Wrapper } from './styled';

const pageQuery = graphql`
  {
    introYaml {
      header {
        cs
      }
      text {
        cs
      }
    }
  }
`;

const Intro = () => {
  const locale = 'cs';
  const data = useStaticQuery(pageQuery);

  const headerText = data.introYaml.header[locale];
  const bodyText = data.introYaml.text[locale];

  return (
    <StyledSection>
      <Wrapper id="whatWeDo">
        <TitleText>{headerText}</TitleText>
        <Text>{bodyText}</Text>
      </Wrapper>
    </StyledSection>
  );
};

export default Intro;
