import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  @media screen and (min-width: ${({ theme }) => theme.layout.lg}px) {
    align-items: flex-start;
  }
`;

export const TechHeading = styled.h2`
  color: ${({ theme }) => theme.color.text.primary};
  font-family: 'Roboto Mono';
  font-size: 40px;
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 30px;
  @media screen and (min-width: ${({ theme }) => theme.layout.lg}px) {
  }
`;

export const TechItemsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  max-width: 440px;
  @media screen and (min-width: ${({ theme }) => theme.layout.lg}px) {
    justify-content: flex-start;
  }
`;

export const TechItem = styled.div`
  color: ${({ theme }) => theme.color.text.secondary};
  margin-bottom: 20px;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  min-width: 105px;
  text-align: center;
  @media screen and (min-width: ${({ theme }) => theme.layout.md}px) {
    text-align: initial;
    min-width: 110px;
  }
`;
