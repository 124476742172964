import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 100px;
  width: 100%;
  @media screen and (min-width: ${({ theme }) => theme.layout.lg}px) {
    flex-direction: row;
    justify-content: center;
  }
`;

export const ImageWrapper = styled.div`
  margin-bottom: 10px;
  width: 100%;
  img {
    width: 100%;
    height: auto;
  }
`;

export const ContactWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 175px;
  @media screen and (min-width: ${({ theme }) => theme.layout.lg}px) {
    align-items: flex-start;
    margin-left: 15px;
  }
`;

export const ContactHeading = styled.h3`
  font-family: 'Roboto Mono';
  font-weight: 700;
  font-size: 24px;
  margin-top: 0px;
  margin-bottom: 24px;
  color: ${({ theme }) => theme.color.text.primary};
`;

export const ContactItem = styled.p`
  color: ${({ theme }) => theme.color.text.secondary};
  font-size: 18px;
  margin-bottom: 10px;
  margin-top: 0px;
  a {
    color: ${({ theme }) => theme.color.text.secondary};
    text-decoration: none;
    :hover {
      color: ${({ theme }) => theme.color.text.primary};
    }
  }
`;

export const ContactDivider = styled.div`
  margin-bottom: 20px;
`;

export const Separator = styled.div`
  margin-bottom: 0px;
  @media screen and (min-width: ${({ theme }) => theme.layout.md}px) {
    margin-bottom: 50px;
  }
`;
