import styled from 'styled-components';

export const Text = styled.p`
  font-size: 22px;
  line-height: 40px;
  color: ${({ theme }) => theme.color.text.secondary};
  margin-bottom: 20px;
  margin-top: 0px;
`;

export const Wrapper = styled.div`
  max-width: 500px;
`;

export const AndroidImage = styled.img`
  position: absolute;
  bottom: 100px;
  left: 0px;
  max-width: 100%;
  @media screen and (max-width: ${({ theme }) => theme.layout.lg}px) {
    top: 100px;
  }

  opacity: var(--opa1);
  transform: translateY(var(--ty1));
  transition: transform 0.1s linear;
`;

export const SteveImage = styled.img`
  position: absolute;
  top: 635px;
  right: 0px;
  max-width: 100%;
  @media screen and (min-width: ${({ theme }) => theme.layout.md}px) {
    top: 535px;
  }
  @media screen and (min-width: ${({ theme }) => theme.layout.lg}px) {
    top: 237px;
  }

  opacity: var(--opa2);
  transform: translateY(var(--ty2));
  transition: transform 0.1s linear;
`;
