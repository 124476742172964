import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { TechHeading, TechItem, TechItemsWrapper, Wrapper } from './styled';

interface Props {
  mainHeading: string;
}

const pageQuery = graphql`
  {
    allTechlistYaml {
      nodes {
        name
      }
    }
  }
`;

const Tech = ({ mainHeading }: Props) => {
  const techList = useStaticQuery(pageQuery).allTechlistYaml.nodes;

  return (
    <Wrapper>
      <TechHeading>{mainHeading}</TechHeading>
      <TechItemsWrapper>
        {techList?.map(({ name }: any) => (
          <TechItem key={name}>{name}</TechItem>
        ))}
      </TechItemsWrapper>
    </Wrapper>
  );
};

export default Tech;
