import { RedLink } from 'components/CommonComponents/RedButtons';
import { TitleText } from 'components/CommonComponents/Titles/styled';
import { StyledSection } from 'components/CommonComponents/Wrappers/styled';
import { graphql, useStaticQuery } from 'gatsby';
import React, { Fragment } from 'react';
import {
  DescriptionWrapper,
  HighlightDescription,
  HighlightImageWrapper,
  HighlightProjectName,
  HighlightProjectWrapper,
  ImageWrapper,
  ProjectDescription,
  ProjectName,
  ProjectWrapper,
  Separator,
  StyledImagePhone1,
  StyledImagePhone2,
  StyledLink,
  Wrapper,
} from './styled';

const pageQuery = graphql`
  {
    projectsYaml {
      mainHeading {
        cs
      }
      buttonText {
        cs
      }
      backgroundImages {
        publicURL
      }
    }
    allProjectlistYaml(limit: 4) {
      nodes {
        hasDetail
        color
        logo {
          publicURL
        }
        name
        slug
        description {
          cs
        }
      }
    }
  }
`;

interface ProjectProps {
  highlighted: boolean;
  hasDetail: boolean;
  color: string;
  slug: string;
  image: string;
  description: string;
  name: string;
}

const renderProject = ({ hasDetail, highlighted, color, slug, image, description, name }: ProjectProps) => {
  const WrapperElement = highlighted ? HighlightProjectWrapper : ProjectWrapper;
  const DescriptionElement = highlighted ? HighlightDescription : DescriptionWrapper;
  const ImageElement = highlighted ? HighlightImageWrapper : ImageWrapper;
  const NameElement = highlighted ? HighlightProjectName : ProjectName;

  if (hasDetail) {
    return (
      <WrapperElement>
        <StyledLink to={`/project/${slug}`}>
          <ImageElement backgroundColor={color}>
            <img src={image} />
          </ImageElement>
        </StyledLink>
        <DescriptionElement>
          <NameElement>{name}</NameElement>
          <ProjectDescription>{description}</ProjectDescription>
        </DescriptionElement>
      </WrapperElement>
    );
  }

  return (
    <WrapperElement>
      <ImageElement backgroundColor={color}>
        <img src={image} />
      </ImageElement>
      <DescriptionElement>
        <NameElement>{name}</NameElement>
        <ProjectDescription>{description}</ProjectDescription>
      </DescriptionElement>
    </WrapperElement>
  );
};

const Projects = () => {
  const locale = 'cs';
  const data = useStaticQuery(pageQuery);
  const projectData = data.projectsYaml;
  const list = data.allProjectlistYaml.nodes;

  return (
    <StyledSection>
      <StyledImagePhone2 alt={'projects mobile 2'} src={projectData.backgroundImages[1].publicURL} />
      <TitleText>{projectData.mainHeading[locale]}</TitleText>
      <StyledImagePhone1 alt={'projects mobile 1'} src={projectData.backgroundImages[0].publicURL} />
      <Wrapper>
        {list.map((project: any, index: number) => (
          <Fragment key={'ourWork' + index}>
            {renderProject({
              hasDetail: project.hasDetail,
              color: project.color,
              highlighted: index === 0,
              image: project.logo.publicURL,
              slug: project.slug,
              name: project.name,
              description: project.description[locale],
            })}
          </Fragment>
        ))}
      </Wrapper>
      <RedLink text={data.projectsYaml.buttonText[locale]} to={'/references'} />
      <Separator />
    </StyledSection>
  );
};

export default Projects;
