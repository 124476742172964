import { RedLink } from 'components/CommonComponents/RedButtons';
import { TitleText } from 'components/CommonComponents/Titles/styled';
import { StyledSection } from 'components/CommonComponents/Wrappers/styled';
import { ImageCar } from 'components/PageHome/KnowHow/styled';
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { ContactDivider, ContactHeading, ContactItem, ContactWrapper, ImageWrapper, Separator, Wrapper } from './styled';

const pageQuery = graphql`
  {
    contactYaml {
      mainHeading {
        cs
      }
      buildingName {
        cs
      }
      contact {
        cs
      }
      buttonText {
        cs
      }
      backgroundImage {
        publicURL
      }
      image {
        publicURL
      }
    }
  }
`;

const OurOffices = () => {
  const locale = 'cs';
  const data = useStaticQuery(pageQuery).contactYaml;
  return (
    <StyledSection>
      <ImageCar src={data.backgroundImage.publicURL} />
      <TitleText>{data.mainHeading[locale]}</TitleText>
      <Separator />
      <Wrapper>
        <ImageWrapper>
          <img src={data.image.publicURL} />
        </ImageWrapper>
        <ContactWrapper>
          <ContactHeading>{data.buildingName[locale]}</ContactHeading>
          <ContactDivider />
          <ContactItem>{data.contact[locale][0]}</ContactItem>
          <ContactItem>{data.contact[locale][1]}</ContactItem>
          <ContactItem>{data.contact[locale][2]}</ContactItem>
          <ContactDivider />
          <ContactItem>
            <a href={`tel:${data.contact[locale][3]}`}>{data.contact[locale][3]}</a>
          </ContactItem>
          <ContactItem>
            <a href={`mailto:${data.contact[locale][4]}`}>{data.contact[locale][4]}</a>
          </ContactItem>
        </ContactWrapper>
      </Wrapper>
      <RedLink text={data.buttonText[locale]} to={'/offices'} />
    </StyledSection>
  );
};

export default OurOffices;
