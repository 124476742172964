import loadable from '@loadable/component';
import { StyledSection } from 'components/CommonComponents/Wrappers/styled';
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { WeAreDevelopers, WeAreHeading, WeAreProviding, Wrapper } from './styled';

const TypingEffect = loadable(() => import('react-typing-effect'));

const pageQuery = graphql`
  {
    allWeareYaml {
      nodes {
        heading
        weAreList
        motto
      }
    }
  }
`;

const WeAre = () => {
  const data = useStaticQuery(pageQuery);

  const texts = data.allWeareYaml.nodes[0];

  return (
    <StyledSection>
      <Wrapper>
        <WeAreHeading>{texts.heading}</WeAreHeading>
        <WeAreDevelopers>
          <TypingEffect typingDelay={300} eraseDelay={3000} speed={200} text={texts.weAreList} />
        </WeAreDevelopers>
        <WeAreProviding>{texts.motto}</WeAreProviding>
      </Wrapper>
    </StyledSection>
  );
};

export default WeAre;
